import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import AwardsWrapper from './AwardsStyles.style';

export default function Awards({ awards }) {
  return (
    <>
      <AwardsWrapper>
        {awards.map((award) => (
          <div className="award__item">
            <GatsbyImage
              image={award.imageAward.asset.gatsbyImageData}
              alt={award.imageAward.asset.originalFilename}
            />
            <div className="award__item--content">
              <h5>{award.name}</h5>
              <p>{award.shortdesc}</p>
              <div className="award__item--content-link">
                <Link to={`/award/${award.slug.current}`}>Chi tiết</Link>
              </div>
            </div>
          </div>
        ))}
      </AwardsWrapper>
    </>
  );
}
