import styled from 'styled-components';
import tw from 'twin.macro';

const AwardsWrapper = styled.div`
  ${tw`grid gap-8 sm:mx-auto lg:grid-cols-4`}
  .award__item {
    ${tw`text-center transition duration-300 transform rounded shadow-sm hover:-translate-y-2 hover:shadow`}
    img {
      ${tw`object-cover rounded-t `};
    }
    .award__item--content {
      ${tw`px-2 py-4`};
      h5 {
        ${tw`mb-2 text-lg font-bold`}
      }
      p {
        ${tw`mb-2 text-sm text-gray-700`};
      }
      .award__item--content-link {
        ${tw`flex items-center justify-center`}
        a {
          ${tw`px-3 py-2 font-medium transition duration-200 border rounded shadow-md border-primary text-primary hover:bg-primary hover:text-primary_light`};
        }
      }
    }
  }
`;

export default AwardsWrapper;
