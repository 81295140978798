import React from 'react';
import { graphql } from 'gatsby';
import Awards from '../components/Awards/Awards.component';
import Pagination from '../components/Pagination.component';
import Seo from '../components/Seo.component';
import styled from 'styled-components';
import tw from 'twin.macro';

const AwardsPageStyle = styled.main`
  ${tw`container py-10 pt-3 pb-5 mx-auto`}
`;

export default function AwardsPage({ data, pageContext }) {
  const awards = data?.awards?.nodes;
  return (
    <AwardsPageStyle>
      <Seo title={`Chứng nhận - Trang ${pageContext.currentPage || 1}`} />
      <Pagination
        pageSize={parseInt(process.env.GATSBY_PAGE_SIZE)}
        totalCount={data?.awards.totalCount}
        currentPage={pageContext.currentPage || 1}
        skip={pageContext.skip}
        base="/awards"
      />
      <Awards awards={awards} />
    </AwardsPageStyle>
  );
}

export const query = graphql`
  query ($skip: Int = 0, $pageSize: Int = 2) {
    awards: allSanityAward(limit: $pageSize, skip: $skip) {
      totalCount
      nodes {
        id
        name
        slug {
          current
        }
        shortdesc
        description {
          _rawChildren
        }
        imageAward {
          asset {
            originalFilename
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
