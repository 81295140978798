import React from 'react';
import { Link } from 'gatsby';
import { GrLinkPrevious, GrLinkNext } from 'react-icons/gr';
import styled from 'styled-components';
import tw from 'twin.macro';
const PaginationStyles = styled.div`
  ${tw`flex justify-center pt-8 pb-4`}
  a {
    ${tw`flex items-center px-4 py-2 mx-1 text-gray-700 transition-colors duration-300 transform bg-white rounded-full dark:bg-gray-800 dark:text-gray-200 hover:bg-primary dark:hover:bg-secondary_dark hover:text-white dark:hover:text-secondary`}
    &[aria-current],
    &.current {
      ${tw`text-white bg-primary dark:bg-secondary_dark dark:text-secondary`}
    }
    &[disabled] {
      ${tw`opacity-50 cursor-not-allowed pointer-events-none`}
    }
  }
  /* display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 1.5rem;
  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    flex: 0 1 auto;
    text-decoration: none;
    border: 1px solid var(--black);
    border-radius: 50%;
    margin: 0.5rem;
    &[aria-current],
    &.current {
      color: var(--white);
      min-width: 20px;
      background: var(--yellow);
    }
    &[disabled] {
      pointer-events: none;
      opacity: 0.2;
      color: var(--grey);
    }
  }
  @media (max-width: 992px) {
    .word {
      display: none;
    }
    font-size: 1.4rem;
  } */
`;

export default function Pagination({
  pageSize,
  totalCount,
  currentPage,
  skip,
  base,
}) {
  const totalPages = Math.ceil(totalCount / pageSize);
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const hasNextPage = nextPage <= totalPages;
  const hasPrevPage = prevPage >= 1;
  return (
    <PaginationStyles>
      <Link
        title="page prev"
        disabled={!hasPrevPage}
        to={`${base}/${prevPage}`}
      >
        <GrLinkPrevious />
      </Link>
      {Array.from({ length: totalPages }).map((_, i) => (
        <Link
          className={currentPage === 1 && i === 0 ? 'current' : ''}
          to={`${base}/${i > 0 ? i + 1 : ''}`}
          key={i}
        >
          {i + 1}
        </Link>
      ))}
      <Link
        title="page next"
        disabled={!hasNextPage}
        to={`${base}/${nextPage}`}
      >
        <GrLinkNext />
      </Link>
    </PaginationStyles>
  );
}
